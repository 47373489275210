import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroSection from "../components/pages/Index/HeroSection"
import {
  blogsSection,
  faqs,
  helpWithNDISSection,
  heroSection,
  resourcesSection,
} from "../content/Index"
import ServicesSection from "../components/common/ServicesSection"
import {
  contactSection,
  featuredPropertiesSection,
  houseDetailsSection,
  servicesSection,
  testimonialsSection,
} from "../content/Common"
import PropertiesCardsSection from "../components/common/PropertiesCardsSection"
import HouseDetailsSection from "../components/common/HouseDetailsSection"
import ResourcesSection from "../components/common/ResourcesSection"
import BlogsSection from "../components/common/BlogsSection"
import TestimonialsSection from "../components/common/TestimonialsSection"
import FaqsSection from "../components/common/FaqsSection"
import ContactSection from "../components/common/ContactSection"
import CTASection from "../components/molecules/CTASection"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />

    <HeroSection data={heroSection} />

    <div className="cc-section--horizontal-space text-center">
      <CTASection data={helpWithNDISSection} />
    </div>

    <ServicesSection data={servicesSection} />

    <PropertiesCardsSection data={featuredPropertiesSection} />

    <HouseDetailsSection data={houseDetailsSection} />

    <ResourcesSection data={resourcesSection} />

    <BlogsSection data={blogsSection} />

    <TestimonialsSection data={testimonialsSection} />


    <FaqsSection faqs={faqs} />

    <ContactSection data={contactSection} sectionTitleAlignment="center" />
  </Layout>
)

export default IndexPage
