import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const FontAwesomIconListItem = ({ icon, text, textEC }) => {
  return (
    <div className="flex gap-3 text-[16px] md:text-[18px] leading-[1.4em] items-center">
      <FontAwesomeIcon icon={icon} className="text-[#1C5C4F]" />
      <span className={textEC}>{text}</span>
    </div>
  )
}

export default FontAwesomIconListItem
