import React from "react"
import ImageRenderer from "./ImageRenderer"

const BrandMainImageOrVideo = ({ image, imageEC, video, videoEC }) => {
  return (
    <div className="relative">
      <div className="absolute z-[1] left-[12%] md:left-[16%] lg:left-[22%] top-[25px] md:top-[30px]">
        <ImageRenderer
          img={`global-image-design--quotation-image`}
          alt={`global-image-design--quotation-image`}
        />
      </div>
      <div className="absolute z-[1] bottom-[-20px] md:bottom-[-40px] right-[-20px] lg:right-[15px] max-w-[70px] md:max-w-[100%]">
        <ImageRenderer
          img={`global-image-design--brush-image`}
          alt={`global-image-design--brush-image`}
        />
      </div>
      {video && (
        <video
          width="600"
          autoPlay
          muted
          loop
          className={`rounded-tl-[50%] rounded-tr-[50%] rounded-br-[15px] border-r-[12px] border-[#1C5C4F] max-w-[430px] w-full mx-auto ${videoEC}`}
        >
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      )}

      {image && (
        <div className={`${imageEC}`}>
          <ImageRenderer img={image.path} alt={image.alt} />
        </div>
      )}
    </div>
  )
}

export default BrandMainImageOrVideo
