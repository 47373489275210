import React from "react"
import Button from "../atoms/Button"
import SectionTitle from "../atoms/SectionTitle"

const CTASection = ({ data }) => {
  return (
    <div className={` ${data.componentEC}`}>
      {data.title && (
        <SectionTitle
          title={data.title}
          titleEC={data.titleEC}
          componentEC={data.titleComponentEC}
          isTopImage={false}
        />
      )}
      {data.description ? (
        <p
          className={
            "cta-description mb-5 sm:mb-1 md:mb-5 lg:mb-10 font-default text-[16px] md:text-[16px] lg:text-[18px] font-poppins text-[#202020] " +
              data.descriptionEC || ""
          }
          dangerouslySetInnerHTML={{ __html: data.description }}
        />
      ) : (
        ""
      )}
      {data.btns && (
        <div className={`flex flex-wrap gap-[10px] ${data.btnsWrapperEC} `}>
          {data.btns.map((btn, index) => (
            <Button
              key={"heroBtn_" + index}
              to={btn.to}
              href={btn.href}
              text={btn.text}
              ec={btn.ec}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default CTASection
