import React from "react"
import ImageRenderer from "../atoms/ImageRenderer"
import SectionTitle from "../atoms/SectionTitle"
import BrandParagraph from "../atoms/BrandParagraph"
import Button from "../atoms/Button"
import { siteDetails } from "../../content/Global"
import { faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import BrandMainImageOrVideo from "../atoms/BrandMainImageOrVideo"

const HouseDetailsSection = ({ data }) => {
  return (
    <div className="cc-section--horizontal-space cc-section--vertical-space">
      <div className=" flex flex-col md:flex-row gap-[60px] items-center">
        <div className="flex-[1]">
          <SectionTitle
            title={data.sectionTitle}
            titleEC={`mb-3 ${data.sectionTitleEC}`}
          />
          <BrandParagraph text={data.description} />
          {data.houseDetails && (
            <div className="mt-8 pb-8 border-b border-[#d7d7d7]">
              <h4 className="text-[18px] leading-[1.4em] font-[600] text-secondary mb-4">
                House Details
              </h4>
              <div className="flex flex-wrap gap-[20px] pl-4">
                {data.houseDetails.map((item, index) => (
                  <div
                    className="flex items-center gap-3 w-[46%]"
                    key={"houseDetails_" + index}
                  >
                    <FontAwesomeIcon
                      icon={item.icon}
                      className="text-[#1C9F85]"
                    />
                    <span className="text-[#858585]">{item.text}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          {data.isShowAuthor && (
            <div className="flex justify-between items-center gap-[5px] md:gap-[20px] mt-10">
              <div className="flex items-center gap-[15px]">
                <div className="max-w-[40px]">
                  <ImageRenderer
                    img={siteDetails.author.image.path}
                    alt={siteDetails.author.image.alt}
                  />
                </div>
                <div>
                  <h4 className="text-[18px] leading-[1.4em] font-[600] text-secondary">
                    {siteDetails.author.name}
                  </h4>
                  <p className="text-[12px] leading-[1.4em] text-[#858585]">
                    {siteDetails.author.designation}
                  </p>
                </div>
              </div>
              <Button
                to={siteDetails.phoneLink}
                href={siteDetails.phoneLink}
                text={`Contact Us`}
                ec={`primary-btn`}
                icon={faPhone}
                iconEC={`text-secondary`}
              />
            </div>
          )}
        </div>
        <div className="flex-[1]">
          <BrandMainImageOrVideo video={data.sectionMainVideo} />
        </div>
      </div>

      {data.extraInfo && <BrandParagraph text={data.extraInfo} textEC={`mt-4`} />}
    </div>
  )
}

export default HouseDetailsSection
