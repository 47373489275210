import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"
import { siteDetails } from "./Global"

// Hero Section
export const heroSection = {
  sectionBgImage: `home--hero-section-bg`,
  title: `Premier NDIS <br><span>SDA</span> Housing Solutions for Independent Living`,
  description: `Experience the excellence of Care Casa’s exclusive <span>NDIS SDA</span> housing in Australia, which empowers people with disabilities to live independently with superior quality and comfort.`,
  formFieldIcon: faMapMarkerAlt,
  formFieldPlaceholderText: `Search by State, Suburb or Postcode`,
  formBtnText: `Search`,
  sectionMainImage: {
    path: `home--hero-section-image`,
    alt: `home--hero-section-image`,
  },
  googleReviewsImage: {
    path: `home--hero-section-google-reviews`,
    alt: `home--hero-section-google-reviews`,
  },
  infoBoxes: [
    {
      title: `50+`,
      description: `Our Property`,
    },
    {
      title: `100+`,
      description: `Happy Customer`,
    },
  ],
}

export const helpWithNDISSection = {
  title: `We can Help with <span>NDIS</span>`,
  description: `Care Casa offers services designed to enhance the quality of life of NDIS participants and help them maximise their NDIS funding. Care Casa assists participants in identifying and pursuing their unique needs and goals, from planning personalised care to finding appropriate service providers. In addition to helping clients manage their NDIS plans, Care Casa assists them in understanding how funds are allocated effectively, ensuring they have access to essential supports like therapies and daily assistance. Care Casa also helps adapt plans to reflect changes, such as price updates or new service categories, based on the latest NDIS guidelines. As a result, participants are empowered to make informed choices, promoting independence and being able to access high-quality, flexible care.`,
  btns: [
    {
      to: "/contact-us/",
      href: "#",
      text: "Get started",
      ec: "primary-btn",
    },
    {
      to: siteDetails.phoneLink,
      href: siteDetails.phoneLink,
      text: "Talk to our Expert",
      ec: "secondary-btn",
    },
  ],
  btnsWrapperEC: `justify-center items-center`
}

// Resourses Section
export const resourcesSection = {
  sectionBgImage: {
    path: `home--resources-section-image`,
    alt: `home--resources-section-image`,
  },
  sectionTitle: `<span>Resources</span>`,
  description: `Care Casa allows you to explore valuable resources designed to help you receive guidance on Specialist Disability Accommodation (SDA). These resources cover all aspects of the process, from understanding the various types of SDA and their features to managing your NDIS plan and finding suitable accommodations to help you make informed decisions. They also include explicit information on assessing your needs, connecting with appropriate care providers, and utilising assistive technologies to enhance your living experience. If you're a care provider, a care seeker or a supporter, these resources offer the insights and support you need to ensure a smooth and thriving transition into suitable and adequate housing solutions.`,
  extraDescription: `Select the icons below to find out more.`,
  iconBoxes: [
    {
      iconImage: {
        path: `home--resources-icon1`,
        alt: `home--resources-icon1`,
      },
      iconImageHovered: {
        path: `home--resources-icon1-hovered`,
        alt: `home--resources-icon1-hovered`,
      },
      text: `Care Seeker Resources`,
    },
    {
      iconImage: {
        path: `home--resources-icon2`,
        alt: `home--resources-icon2`,
      },
      iconImageHovered: {
        path: `home--resources-icon2-hovered`,
        alt: `home--resources-icon2-hovered`,
      },
      text: `Care Provider Resources`,
    },
    {
      iconImage: {
        path: `home--resources-icon3`,
        alt: `home--resources-icon3`,
      },
      iconImageHovered: {
        path: `home--resources-icon3-hovered`,
        alt: `home--resources-icon3-hovered`,
      },
      text: `Supporter Resources`,
    },
  ],
}

// Blogs Section
export const blogsSection = {
  sectionTitle: `Our <span>Blogs & News</span>`,
  btnText: `View More Blogs & News`,
  btnLink: `/blogs`,
}

// Faqs Section
export const faqs = [
  {
    question: `What is Specialist Disability Accommodation (SDA), and how does Care Casa support it?`,
    answer: `Specialist Disability Accommodation (SDA) refers to personalised housing options with support services designed to assist individuals with disabilities in living as independently as possible. Care Casa provides tailored SDA solutions, including accessible housing options and personalised support plans that cater to each resident's unique needs.`,
  },
  {
    question: `Who is eligible to live at Care Casa?`,
    answer: `Care Casa welcomes individuals with disabilities who require specialist disability accommodation. Our homes are designed to accommodate various needs, and we work closely with potential residents and their families to ensure suitability and compatibility.`,
  },
  {
    question: `How does Care Casa assist with navigating the National Disability Insurance Scheme (NDIS)?`,
    answer: `We have dedicated NDIS coordination services to help residents navigate the complexities of the NDIS. Our team assists with planning, funding applications, and connecting residents with the proper support and services under their NDIS plan.`,
  },
  {
    question: `What types of accommodations does Care Casa offer?`,
    answer: `Care Casa offers a variety of accommodation options tailored to meet the diverse needs of our residents. These include single-bedroom apartments, shared living arrangements, and family-style homes, all with accessibility features and designed for comfort.`,
  },
  {
    question: `What support services are available at Care Casa?`,
    answer: `Our support services are comprehensive and personalised. They include assistance with daily living activities, personal care support, community participation programs, and access to healthcare services. Each resident receives a personalised support plan based on their needs and preferences.`,
  },
  {
    question: `How does Care Casa promote community engagement among residents?`,
    answer: `We prioritise community engagement through various activities and programs. Residents can participate in social events, recreational activities, and educational workshops. These initiatives foster a sense of belonging and encourage social interaction among residents.`,
  },
  {
    question: `How does Care Casa promote community engagement among residents?`,
    answer: `We prioritise community engagement through various activities and programs. Residents can participate in social events, recreational activities, and educational workshops. These initiatives foster a sense of belonging and encourage social interaction among residents.`,
  },
  {
    question: `What safety and accessibility features are available in Care Casa homes?`,
    answer: `Our homes are designed with safety and accessibility in mind. Features include wheelchair-accessible entrances, modified bathrooms and kitchens, emergency call systems, secure parking options, and accessibility to common areas within the community.`,
  },
  {
    question: `How can I schedule a tour of Care Casa or apply for residency?`,
    answer: `We encourage anyone interested in learning about Care Casa to schedule a tour of our facilities. During the tour, you'll see our accommodations and meet our team. To apply for residency, contact us directly, and we'll guide you through the application process, including eligibility requirements and availability.`,
  },
  // More questions...
]
